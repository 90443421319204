import {makeDeletePeriodicProductFeeCall, makeGetMembershipFeesCall} from "../../services/product-service";
import {MembershipFee} from "../../models/membership-fee/membership-fee";
import {useLoaderData} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {MEMBERSHIP_FEE_DISCRIMINATOR} from "../../constants";
import PeriodicProductFeesList from "../../components/periodic-product-fees-list";
import YearDropdown from "../../components/forms/year-dropdown";

export const listMembershipFeesLoader = async(): Promise<MembershipFee[] | undefined> => {
  return (await makeGetMembershipFeesCall())?.data;
};

export default function ListMembershipFees() {
  const getMembershipFeesResponse = useLoaderData() as MembershipFee[];
  const [membershipFees, setMembershipFees] = useState<MembershipFee[]>(getMembershipFeesResponse);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());

  useEffect(() => {
    let membershipFees: MembershipFee[] = getMembershipFeesResponse;
    if (selectedYear) {
      membershipFees = getMembershipFeesResponse.filter((membershipFee) => new Date(membershipFee.startDate).getUTCFullYear() === selectedYear);
    }

    setMembershipFees(membershipFees);
  }, [selectedYear]);

  const deleteMembershipFee = async (membershipFeeId: string) => {
    const succeeded = await makeDeletePeriodicProductFeeCall(membershipFeeId);
    if (succeeded) {
      setMembershipFees(membershipFees.filter((membershipFee) => membershipFee.id !== membershipFeeId));
    }
  }

  return (
    <Row>
      <h2>Cuotas sociales</h2>
      <h3>Listado</h3>
      <Row md={6}>
        <YearDropdown selectedYear={selectedYear} setSelectedYear={setSelectedYear} products={membershipFees}/>
      </Row>
      <Row>
        <PeriodicProductFeesList discriminator={MEMBERSHIP_FEE_DISCRIMINATOR} periodicProducts={membershipFees} deleteFunction={deleteMembershipFee}/>
      </Row>
    </Row>
  )
}
