import FormInput from "./form-input";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {PeriodicProduct} from "../../models/periodic-product";
import {Activity} from "../../models/activity";

interface Props {
  selectedYear: number;
  setSelectedYear: Dispatch<SetStateAction<number>>;
  products: PeriodicProduct[];
  selectedActivity?: Activity;
}

export default function YearDropdown(props: Props) {
  const [years, setYears] = useState<{key: string; value: string}[]>([]);

  useEffect(() => {
    getSelectOptions();
  }, [props.selectedActivity]);

  useEffect(() => {
    getSelectedYear();
  }, [years]);

  const getYearsList = () => {
    const years: number[] = [];
    if (!props.products || props.products.length === 0) {
      return years;
    }

    let products = props.products;
    if (props.selectedActivity) {
      products = products.filter((p) => p.activityId === props.selectedActivity!.id);
    }

    for (const product of products) {
      const date = new Date(product.dueDate);
      const year = date.getFullYear();
      if (!years.includes(year)) {
        years.push(year);
      }
    }

    return years;
  }

  const getSelectOptions = () => {
    const selectOptions = [
      {
        key: '',
        value: 'Todos'
      }
    ];

    for (const year of getYearsList()) {
      selectOptions.push({
        key: year.toString(),
        value: year.toString()
      })
    }

    setYears(selectOptions);
  }

  const getSelectedYear = () => {
    let year = 0;
    if (years.length) {
      year = Number(years[years.length-1].value);
    }

    props.setSelectedYear(year);
  }

  return (
    <FormInput
      controlId="year"
      label="Año"
      type="select"
      name="year"
      value={props.selectedYear}
      onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => { props.setSelectedYear(Number(e.target.value)) }}
      onBlurEvent={() => {}}
      touchedField={null}
      errorField={null}
      isDisabled={false}
      selectOptions={years}
    />
  )
}
