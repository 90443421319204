import {Button, Col, Form, Row, Stack} from "react-bootstrap";
import {FormikValues, useFormik} from "formik";
import {makeGetNewMemberNumberCall, makePatchMembersCallV2, makePostMembersCallV2} from "../../services/member-service";
import {Member} from "../../models/member/member";
import {Operation} from "../../models/operation";
import {Link} from "react-router-dom";
import FormInput from "./form-input";
import {makeGetValuesListsCall} from "../../services/values-lists-service";
import {
  BUTTON_CONFIRM_UPDATE_LABEL,
  BUTTON_CREATE_NEW_LABEL,
  BUTTON_EDIT_LABEL,
  LOOKUP_LIST_ID_GENDER,
  LOOKUP_LIST_ID_MEMBER_STATUS,
  LOOKUP_LIST_ID_MEMBER_TYPE,
  TOAST_SUCCESSFUL_OPERATION_TITLE
} from "../../constants";
import {ChangeEvent, useEffect, useState} from "react";
import {LookupListValue} from "../../models/lookup-list-value";
import {KeyValuePair} from "../../models/key-value-pair";
import {AxiosResponse, HttpStatusCode} from "axios";
import {ToastConfiguration} from "../../models/toast-configuration";
import {MemberSchema} from "../../models/validations/member-schema";
import {getLookupListValueTextById} from "../../helpers";
import Credential from "../members/credential";

interface Props {
  member?: Member;
  operation: Operation;
  toastConfiguration?: ToastConfiguration | null;
  afterSubmitting?: () => void;
}

function MemberForm(props: Props) {
  const [genderKeyValueList, setGenderKeyValueList] = useState<KeyValuePair[]>([]);
  const [memberTypeKeyValueList, setMemberTypeKeyValueList] = useState<KeyValuePair[]>([]);
  const [memberStatusKeyValueList, setMemberStatusKeyValueList] = useState<KeyValuePair[]>([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [image, setImage] = useState<File>();

  const formik = useFormik({
    initialValues: {
      id: props.member?.id,
      firstName: props.member?.firstName ?? '',
      lastName: props.member?.lastName ?? '',
      documentNumber: props.member?.documentNumber ?? '',
      genderId: props.member?.gender?.id ?? '146F90AD-FA7E-42E6-8965-75E34110CB78',
      memberNumber: props.member?.memberNumber ?? '',
      membershipDate: new Date(
        props.member != null ? props.member?.membershipDate : Date.now()
      )?.toISOString()
        .split('T')[0],
      memberTypeId: props.member?.memberType.id ?? '',
      memberStatusId: props.member?.status.id ?? '',
      birthdate: new Date(
        props.member != null ? props.member?.birthdate : Date.now()
      )?.toISOString()
        .split('T')[0],
      address: {
        street: props.member?.contact?.street ?? '',
        number: props.member?.contact?.number ?? '',
        floor: props.member?.contact?.floor ?? '',
        apartment: props.member?.contact?.apartment ?? '',
        postcode: props.member?.contact?.postcode ?? (props.operation === Operation.Add ? '8000' : ''),
        city: props.member?.contact?.city ?? (props.operation === Operation.Add ? 'Bahía Blanca' : ''),
        state: props.member?.contact?.state ?? (props.operation === Operation.Add ? 'Buenos Aires' : ''),
        country: props.member?.contact?.country ?? (props.operation === Operation.Add ? 'Argentina' : ''),
        homePhoneNumber: props.member?.contact?.homePhoneNumber ?? '',
        mobilePhoneNumber: props.member?.contact?.mobilePhoneNumber ?? (props.operation === Operation.Add ? '291' : ''),
        email: props.member?.contact?.email ?? ''
      },
    },
    validationSchema: MemberSchema,
    onSubmit: async () => {
      props.toastConfiguration?.setShow(true);
      let response: AxiosResponse | null;
      if (props.operation === Operation.Add) {
        response = await makePostMembersCallV2({
          id: props.member?.id ?? '',
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          documentNumber: Number(formik.values.documentNumber),
          birthdate: new Date(formik.values.birthdate),
          genderId: formik.values.genderId,
          memberNumber: Number(formik.values.memberNumber),
          membershipDate: new Date(formik.values.membershipDate),
          memberTypeId: formik.values.memberTypeId,
          statusId: formik.values.memberStatusId,
          contact: {
            street: formik.values.address.street,
            number: Number(formik.values.address.number),
            floor: formik.values.address.floor,
            apartment: formik.values.address.apartment,
            city: formik.values.address.city,
            postcode: formik.values.address.postcode,
            state: formik.values.address.state,
            country: formik.values.address.country,
            homePhoneNumber: formik.values.address.homePhoneNumber,
            mobilePhoneNumber: formik.values.address.mobilePhoneNumber,
            email: formik.values.address.email,
            isCurrent: true
          }
        }, image);
      }
      else {
        response = await makePatchMembersCallV2(getChangedValues(formik.values, formik.initialValues), image);
      }

      if (response?.status === HttpStatusCode.Ok || response?.status === HttpStatusCode.Created) {
        props.toastConfiguration?.setOperationSuccessful(true);
        props.toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
        props.toastConfiguration?.setBody('El socio ha sido editado exitosamente.');
        if (props.operation === Operation.Add) {
          props.toastConfiguration?.setBody('El socio ha sido cargado exitosamente.');
          formik.resetForm();
          setImageUrl(undefined);
        }
      }
    },
  });

  useEffect(() => {
    const getValuesLists = async () => {
      if (props.operation === Operation.Add) {
        const newMemberNumber = (await makeGetNewMemberNumberCall());
        formik.initialValues.memberNumber = newMemberNumber?.MemberNumber ?? '';
      }
      const genderListValues = (await makeGetValuesListsCall(LOOKUP_LIST_ID_GENDER))?.data[0].lookupListValues as LookupListValue[];
      const memberTypeListValues = (await makeGetValuesListsCall(LOOKUP_LIST_ID_MEMBER_TYPE))?.data[0].lookupListValues as LookupListValue[];
      const memberStatusListValues = (await makeGetValuesListsCall(LOOKUP_LIST_ID_MEMBER_STATUS))?.data[0].lookupListValues as LookupListValue[];
      const genderKeyValueList: KeyValuePair[] = [];
      const memberTypeKeyValueList: KeyValuePair[] = [];
      const memberStatusKeyValueList: KeyValuePair[] = [];

      if (props.member?.imagePath) {
        setImageUrl(props.member.imagePath);
      }

      genderListValues.map((gender) => {
        genderKeyValueList.push({ key: gender.id as string, value: gender.value });
      });

      memberTypeListValues.map((memberType) => {
        memberTypeKeyValueList.push({ key: memberType.id as string, value: memberType.value });
      });

      memberStatusListValues.map((memberStatus) => {
        memberStatusKeyValueList.push({ key: memberStatus.id as string, value: memberStatus.value });
      });

      setGenderKeyValueList(genderKeyValueList);
      setMemberTypeKeyValueList(memberTypeKeyValueList);
      setMemberStatusKeyValueList(memberStatusKeyValueList);

      if (props.operation === Operation.Add) {
        formik.values.genderId = genderKeyValueList.at(0)?.key ?? '';
        formik.values.memberTypeId = memberTypeKeyValueList.at(0)?.key ?? '';
        formik.values.memberStatusId = memberStatusKeyValueList.at(0)?.key ?? '';
      }
    }

    getValuesLists().then();
  }, [])

  const getChangedValues = (values: FormikValues, initialValues: FormikValues) => {
    return Object
      .entries(values)
      .reduce((acc: any, [key, value]) => {
        const hasChanged = initialValues[key] !== value || key === 'id'

        if (hasChanged) {
          acc[key] = value
        }

        return acc
      }, {})
  }

  const showButton = (): JSX.Element => {
    switch (props.operation) {
      case Operation.Add:
        return (<Button variant="primary" type="submit">{BUTTON_CREATE_NEW_LABEL}</Button>)
      case Operation.Edit:
        return (<Button variant="primary" type="submit">{BUTTON_CONFIRM_UPDATE_LABEL}</Button>)
      case Operation.View:
        return (
          <>
            <Button variant="warning" type="submit">
              <Link to={`../edit/${props.member?.id}`} className="link-button-primary">{BUTTON_EDIT_LABEL}</Link>
            </Button>
            <Button variant="danger" type="submit">Dar de baja</Button>
          </>
        )
    }
  }

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <h3>Datos personales</h3>
        <Row>
          <Col xs={4} md={5}>
            <FormInput
              controlId="formGridFirstName"
              label="Nombres"
              type="text"
              name="firstName"
              value={formik.values.firstName.toUpperCase()}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.firstName}
              errorField={formik.errors.firstName}
              placeholder="JUAN"
              isDisabled={props.operation === Operation.View}
            />
          </Col>

          <Col xs={4} md={5}>
            <FormInput
              controlId="formGridLastName"
              label="Apellidos"
              type="text"
              name="lastName"
              value={formik.values.lastName.toUpperCase()}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.lastName}
              errorField={formik.errors.lastName}
              placeholder="PÉREZ"
              isDisabled={props.operation === Operation.View}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={3}>
            <FormInput
              controlId="formGridDocumentNumber"
              label="Número de documento"
              type="number"
              name="documentNumber"
              value={formik.values.documentNumber}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.documentNumber}
              errorField={formik.errors.documentNumber}
              placeholder={props.operation === Operation.Add ? '30000000' : ''}
              isDisabled={props.operation === Operation.View}
            />
          </Col>

          <Col xs={3}>
            <FormInput
              controlId="formGridBirthdate"
              label="Fecha de nacimiento"
              type="date"
              name="birthdate"
              value={formik.values.birthdate}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.birthdate}
              errorField={formik.errors.birthdate}
              placeholder={new Date().toISOString()}
              isDisabled={props.operation === Operation.View}
            />
          </Col>

          <Col xs={4}>
            <FormInput
              controlId="formGridGenderId"
              label="Género"
              type="select"
              name="genderId"
              value={formik.values.genderId}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.genderId}
              errorField={formik.errors.genderId}
              placeholder={""}
              isDisabled={props.operation === Operation.View}
              selectOptions={genderKeyValueList}
              isSelectWithValueAlreadySelected={true}
            />
          </Col>
        </Row>

        <h3>Datos de socio</h3>
        <Row>
          <Stack id="member-data-stack" direction="horizontal">

            <Col xs={3}>
              <Row>
                <FormInput
                  controlId="formGridMemberNumber"
                  label="Número de socio"
                  type="text"
                  name="memberNumber"
                  value={formik.values.memberNumber}
                  onChangeEvent={formik.handleChange}
                  onBlurEvent={formik.handleBlur}
                  touchedField={formik.touched.memberNumber}
                  errorField={formik.errors.memberNumber}
                  placeholder="1234"
                  isDisabled={props.operation === Operation.View}
                />
              </Row>

              <Row>
                <FormInput
                  controlId="formGridMembershipDate"
                  label="Fecha de asociación"
                  type="date"
                  name="membershipDate"
                  value={formik.values.membershipDate}
                  onChangeEvent={formik.handleChange}
                  onBlurEvent={formik.handleBlur}
                  touchedField={formik.touched.membershipDate}
                  errorField={formik.errors.membershipDate}
                  placeholder={new Date().toISOString()}
                  isDisabled={props.operation === Operation.View}
                />
              </Row>

              <Row>
                <FormInput
                  controlId="formGridMemberType"
                  label="Tipo de socio"
                  type="select"
                  name="memberTypeId"
                  value={formik.values.memberTypeId}
                  onChangeEvent={formik.handleChange}
                  onBlurEvent={formik.handleBlur}
                  touchedField={formik.touched.memberTypeId}
                  errorField={formik.errors.memberTypeId}
                  placeholder={""}
                  isDisabled={props.operation === Operation.View}
                  selectOptions={memberTypeKeyValueList}
                  isSelectWithValueAlreadySelected={true}
                />
              </Row>

              <Row>
                <FormInput
                  controlId="formGridMemberStatus"
                  label="Estado"
                  type="select"
                  name="memberStatusId"
                  value={formik.values.memberStatusId}
                  onChangeEvent={formik.handleChange}
                  onBlurEvent={formik.handleBlur}
                  touchedField={formik.touched.memberStatusId}
                  errorField={formik.errors.memberStatusId}
                  placeholder={""}
                  isDisabled={props.operation === Operation.View}
                  selectOptions={memberStatusKeyValueList}
                  isSelectWithValueAlreadySelected={true}
                />
              </Row>
            </Col>

            <Col xs={7}>
              <div className="d-flex justify-content-center">
                <Credential
                  memberId={formik.values.id ?? ""}
                  memberNumber={formik.values.memberNumber.toString()}
                  firstName={formik.values.firstName}
                  lastName={formik.values.lastName}
                  documentNumber={formik.values.documentNumber.toString()}
                  membershipDate={formik.values.membershipDate}
                  memberType={getLookupListValueTextById(memberTypeKeyValueList, formik.values.memberTypeId)}
                  image={imageUrl}
                />
              </div>
              {
                props.operation !== Operation.View
                  ? (
                    <Row className="mt-2">
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Cargar imagen</Form.Label>
                        <Form.Control type="file" onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setImageUrl(undefined);
                          if (e.target.files) {
                            setImage(e.target.files[0]);
                            setImageUrl(URL.createObjectURL(e.target.files[0])) }
                        }}
                        />
                      </Form.Group>
                    </Row>
                  )
                  : <></>
              }
            </Col>
          </Stack>
        </Row>

        <h3>Datos de contacto</h3>
        <Row>
          <Col xs={4}>
            <FormInput
              controlId="formGridAddressStreet"
              label="Calle"
              type="text"
              name="address.street"
              value={formik.values.address.street.toUpperCase()}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.address?.street}
              errorField={formik.errors.address?.street}
              placeholder={props.operation === Operation.Add ? 'ALSINA' : ''}
              isDisabled={props.operation === Operation.View}
            />
          </Col>

          <Col xs={2}>
            <FormInput
              controlId="formGridAddressNumber"
              label="Número"
              type="text"
              name="address.number"
              value={formik.values.address.number}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.address?.number}
              errorField={formik.errors.address?.number}
              placeholder={props.operation === Operation.Add ? '123' : ''}
              isDisabled={props.operation === Operation.View}
            />
          </Col>

          <Col xs={2}>
            <FormInput
              controlId="formGridAddressFloor"
              label="Piso"
              type="text"
              name="address.floor"
              value={formik.values.address.floor.toUpperCase()}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.address?.floor}
              errorField={formik.errors.address?.floor}
              placeholder={props.operation === Operation.Add ? '1' : ''}
              isDisabled={props.operation === Operation.View}
            />
          </Col>

          <Col xs={2}>
            <FormInput
              controlId="formGridAddressApartment"
              label="Departamento"
              type="text"
              name="address.apartment"
              value={formik.values.address.apartment.toUpperCase()}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.address?.apartment}
              errorField={formik.errors.address?.apartment}
              placeholder={props.operation === Operation.Add ? '1' : ''}
              isDisabled={props.operation === Operation.View}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <FormInput
              controlId="formGridAddressCity"
              label="Ciudad"
              type="text"
              name="address.city"
              value={formik.values.address.city.toUpperCase()}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.address?.city}
              errorField={formik.errors.address?.city}
              placeholder={props.operation === Operation.Add ? 'BAHÍA BLANCA' : ''}
              isDisabled={props.operation === Operation.View}
            />
          </Col>

          <Col xs={2}>
            <FormInput
              controlId="formGridPostcode"
              label="Código postal"
              type="text"
              name="address.postcode"
              value={formik.values.address.postcode.toUpperCase()}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.address?.postcode}
              errorField={formik.errors.address?.postcode}
              placeholder={props.operation === Operation.Add ? '8000' : ''}
              isDisabled={props.operation === Operation.View}
            />
          </Col>

          <Col xs={4}>
            <FormInput
              controlId="formGridAddressState"
              label="Provincia"
              type="text"
              name="address.state"
              value={formik.values.address.state.toUpperCase()}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.address?.state}
              errorField={formik.errors.address?.state}
              placeholder={props.operation === Operation.Add ? 'BUENOS AIRES' : ''}
              isDisabled={props.operation === Operation.View}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <FormInput
              controlId="formGridCountry"
              label="País"
              type="text"
              name="address.country"
              value={formik.values.address.country.toUpperCase()}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.address?.country}
              errorField={formik.errors.address?.country}
              placeholder={props.operation === Operation.Add ? 'ARGENTINA' : ''}
              isDisabled={props.operation === Operation.View}
            />
          </Col>

          <Col xs={3}>
            <FormInput
              controlId="formGridHomePhoneNumber"
              label="Teléfono"
              type="text"
              name="address.homePhoneNumber"
              value={formik.values.address.homePhoneNumber}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.address?.homePhoneNumber}
              errorField={formik.errors.address?.homePhoneNumber}
              placeholder=""
              isDisabled={props.operation === Operation.View}
            />
          </Col>

          <Col xs={3}>
            <FormInput
              controlId="formGridMobilePhoneNumber"
              label="Celular"
              type="text"
              name="address.mobilePhoneNumber"
              value={formik.values.address.mobilePhoneNumber}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.address?.mobilePhoneNumber}
              errorField={formik.errors.address?.mobilePhoneNumber}
              placeholder=""
              isDisabled={props.operation === Operation.View}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <FormInput
              controlId="email"
              label="Correo electrónico"
              type="text"
              name="address.email"
              value={formik.values.address.email}
              onChangeEvent={formik.handleChange}
              onBlurEvent={formik.handleBlur}
              touchedField={formik.touched.address?.email}
              errorField={formik.errors.address?.email}
              placeholder=""
              isDisabled={props.operation === Operation.View}
            />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={4}>
            { showButton() }
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default MemberForm;
