import {Button, Spinner} from "react-bootstrap";
import {ReactElement} from "react";

export interface Props {
  label: string;
  hidden: boolean;
}

export default function SpinningButton(props: Props): ReactElement {
  return (
    <Button variant="primary" disabled hidden={props.hidden}>
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      {props.label}
    </Button>
  );
}
